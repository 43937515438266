<template>
    <div>
        <ul>
            <li v-for="f in requiredFiles[mode]" :key="f.value">{{ f.value }}.{{ f.extension }} ... {{ f.text }}</li>
        </ul>
        <v-file-input
            chips
            multiple
            accept=".csv, .CSV"
            @change="refreshFileList"
            label="ファイルを選択してください"
            v-model="selectedFiles"
            :rules="rules"
            />
        <v-btn
            class="mr-3"
            color="primary"
            :disabled="yw && selectedFiles.length < requiredFiles[mode].length || queueHasDuplicates"
            @click="checkFiles"
            :loading="loading">
            アップロード
        </v-btn>
        <v-progress-circular
            v-show="uploadProgress>0"
            color="primary"
            size="30"
            rotate="270"
            :value="uploadProgress"
            />
        <pre-upload-dialog
            ref="preUploadDialog"
            @file-match="uploadFiles"
            />
    </div>
</template>
<script>
import MessagePack from "what-the-pack";
const { encode } = MessagePack.initialize(2**24);
import PreUploadDialog from './PreUploadDialog'

let ductBufferKey = null;

export default {
    components: {
        PreUploadDialog,
    },
    data: () => ({
        selectedFiles: [],
        queue: [],
        requiredFiles: {
            first: [
                { value: 'shipments', extension: 'csv', text: '実績・引当データ' },
                { value: 'products', extension: 'csv', text: '商品マスタ' },
                { value: 'customers', extension: 'csv', text: '顧客マスタ' },
            ],
            second: [
                { value: 'stocks', extension: 'csv', text: '残在庫データ' },
                { value: 'remains', extension: 'csv', text: '発注残データ' },
            ],
            products_master: [
                { value: 'products', extension: 'csv', text: '商品マスタ' },
            ],
            customers_master: [
                { value: 'customers', extension: 'csv', text: '顧客マスタ' },
            ],
        },
        loading: false,
        uploadProgress: 0,
    }),
    props: ["duct", "mode", "yw"],
    computed: {
        queueIsSynced() {
            return this.queue.every((f,i) => (f==this.selectedFiles[i]))
                && this.selectedFiles.length==this.queue.length;
        },
        queueHasDuplicates() {
            return new Set(this.queue.map((f)=>(f.name))).size!=this.queue.length;
        },
        rules() {
            return[
                !this.queueHasDuplicates || 'ファイル名は重複できません'
            ]
        }
    },
    methods: {
        refreshFileList(files) {
            if(files.length==0) {
                this.selectedFiles = [];
                this.queue = [];
            }
            else if(!this.queueIsSynced){
                this.selectedFiles = [ ...this.queue, ...this.selectedFiles ];
                this.queue = [ ...this.selectedFiles ];
            }
        },
        checkFiles() {
            let uploadList = new Array(this.requiredFiles[this.mode].length);
            this.queue.forEach((f) => {
                this.requiredFiles[this.mode].forEach((_f,i) => {
                    if(f.name==`${_f.value}.${_f.extension}` || f.name==`${_f.value}.${_f.extension.toUpperCase()}`) {
                        uploadList[i] = f;
                        return;
                    }
                });
            });
            if(uploadList.includes(undefined)){
                this.$refs.preUploadDialog.show('file-matching-required', {
                    uploadList,
                    requiredFiles: this.requiredFiles[this.mode],
                    queue: this.queue,
                })
            } else {
                this.uploadFiles(uploadList);
            }
            console.log(uploadList)
        },
        async appendBuffer(bufferKey, blob, chunkSize) {
            for(let head=0; head<blob.length; head+=chunkSize){
                this.uploadProgress = Math.min(blob.length, head+chunkSize)/blob.length*100;
                await this.duct.call(
                    this.duct.EVENT.BLOBS_BUFFER_APPEND,
                    [ bufferKey, blob.slice(head, Math.min(blob.length, head+chunkSize)) ]
                );
            }
        },
        async uploadFiles(uploadList) {
            Promise.all(uploadList.map( (f) => new Promise(
                (resolve, reject) => {
                    if(!f) reject();
                    let fr = new FileReader();
                    fr.onload = () => { resolve(fr.result); }
                    fr.onerror = reject;
                    fr.readAsArrayBuffer(f);
                }
            ) )).then(async (buffers) => {
                await this.duct.call(this.duct.EVENT.BLOBS_BUFFER_OPEN, null);

                let fileBuffersObj = {};
                buffers.forEach((b,i) => {
                    const name = this.requiredFiles[this.mode][i].value;
                    fileBuffersObj[name] = b;
                });
                await this.appendBuffer(ductBufferKey, encode(fileBuffersObj), 1024*512);
                this.duct.send(this.duct.nextRid(), this.duct.EVENT[`FORECAST_UPLOAD_${this.mode.toUpperCase()}`], { bufferKey: ductBufferKey, year: this.yw[0], week: this.yw[1] })
                this.duct.send(this.duct.nextRid(), this.duct.EVENT.BLOBS_BUFFER_OPEN, null);
                this.loading = true;
            });
        },
    },
    created() {
        this.duct.invokeOnOpen(() => {
            this.duct.setEventHandler( this.duct.EVENT.BLOBS_BUFFER_OPEN, (rid,eid,data) => {
                ductBufferKey = data;
            });
            this.duct.send(this.duct.nextRid(), this.duct.EVENT.BLOBS_BUFFER_OPEN, null);
        });
    }
}
</script>

<template>
    <v-dialog
        v-model="dialog"
        max-width="700"
        >
        <v-card v-if="mode=='file-matching-required'">
            <v-card-title>
                <v-icon color="warning">mdi-warning</v-icon>不明なファイルが見つかりました
            </v-card-title>

            <v-card-text>
                ファイルの対応づけを行ってください：<br>
                <v-row v-for="file,i in details.requiredFiles" :key="'upload-file-'+file.text">
                    <v-col cols="9">
                        <v-select 
                            v-model="uploadListValues[i]"
                            :items="details.queue"
                            item-text="name"
                            :label="file.text"
                            :error="uploadListHasDuplicates"
                            />
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="primary"
                    :disabled="uploadList.includes(undefined) || uploadListHasDuplicates"
                    @click="confirmFileMatch()">
                    アップロード
                </v-btn>
                <v-btn text color="grey darken-2" @click="hide()">キャンセル</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data: () => ({
        dialog: false,
        details: {},
        mode: "",
        uploadList: [],
        uploadListValues: [],
        uploadListHasDuplicates: false,
    }),
    methods: {
        show(mode, details={}) {
            this.mode = mode;
            this.details = details;
            this.dialog = true;

            switch(mode) {
                case 'file-matching-required':
                    this.uploadList = [ ...details.uploadList ];
                    this.uploadListValues = details.uploadList.map((f) => (f ? f.name : undefined));
                    break;
            }
        },
        hide() { this.dialog = false; },
        confirmFileMatch() {
            this.$emit("file-match", this.uploadList);
            this.hide();
        },
        fileMatchRules(i) {
            this.uploadListValues.find((v,j)=>(v==this.uploadListValues[i] && i!=j)===undefined) || 'hoge'
        }
    },
    watch: {
        uploadListValues: {
            deep: true,
            handler() {
                this.uploadListValues.forEach((v,i) => {
                    this.uploadList[i] = this.details.queue.find((f) => (f.name==v));
                });
                this.uploadListHasDuplicates = new Set(this.uploadList.map((f)=>(f ? f.name : null))).size!=this.uploadList.length;
            }
        }
    }
}
</script>
